import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/element/layout/Layout";
import PageBanner from "../components/element/page-element/PageBanner";
import LayoutMain from "../components/element/layout/LayoutMain";
import { oo } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import SEO from "../components/element/SEO";
import routes from "../components/navigation/routes";
import { MDXRenderer } from "gatsby-plugin-mdx";

const seo = {
  title: "Terms of Service - Oberion",
  description: null,
  canonical: routes.baseUrl + routes.legal.to,
};

const Terms = (props) => {
  const { data: { legal: { edges } } } = props;
  const { node: { childMdx: { frontmatter, body } } } = edges[0];

  if (frontmatter.title !== "Terms of Service") {
    throw new Error(
      "Error with 'terms.md'. Check markup, template, and GraphQL query for consistency.",
    );
  }
  const pageBanner = <PageBanner heading={frontmatter.title} />;
  const lastUpdate = "Last update: " + frontmatter.date;

  return (
    <Layout showNewsletter>
      <SEO {...seo} />
      {pageBanner}
      <LayoutMain>
        <LayoutColumn maxWidth={oo.textWidth} alignItems="flex-start">
          <article className="markdown">
            <MDXRenderer>{body}</MDXRenderer>
          </article>
          <br />{lastUpdate}
        </LayoutColumn>
      </LayoutMain>
    </Layout>
  );
};

export const query = graphql`
{
  legal: allFile(
    filter: {
      internal: {
        mediaType: {eq: "text/markdown"}}, 
      name: {eq: "terms-of-service"}, 
      sourceInstanceName: {eq: "legal"}}) {
    edges {
      node {
        childMdx {
          body
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  }
}
`;

Terms.propTypes = {
  data: PropTypes.object.isRequired,
};
export default Terms;